import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as fas from '@fortawesome/free-solid-svg-icons'
import * as far from '@fortawesome/free-regular-svg-icons'
import * as fab from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// bulma
Vue.use(Buefy)

// styles
import './style/Main.scss'

// animate.css
import 'animate.css/animate.min.css'

// font awesome
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
library.add([
  fas.faHome, // not used, just holding
  fas.faFingerprint, // main logo for this service
  far.faCircle, // for the circle around the logo
  fas.faUserTie, // any reference to a 'user'
  fab.faSlack, // slack integration
])


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
